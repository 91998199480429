.typeform-widget {
    width: 100%;
    height: 100%;
    min-height: 500px;
}


/*.sd-body.sd-body--static {*/
/*    max-width: 100% !important;*/
/*    padding-bottom: 30px !important;*/
/*}*/

/*.sd-body__navigation {*/
/*    justify-content: center;*/
/*}*/

/*.sd-row__question, .info-panel-image-box {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

/*.sd-question__header {*/
/*    width: auto !important;*/
/*}*/

/*.external-navigation .sv-action__content {*/
/*    justify-content: center;*/
/*}*/

/*.external-navigation input {*/
/*    background-color: rgb(238, 238, 238);*/
/*    color: rgb(103, 58, 176);*/
/*}*/

/*.external-navigation input:hover {*/
/*    background-color: rgb(220, 220, 220);*/
/*}*/

/*h5.sd-title.sd-element__title, div.sd-description.sd-question__description {*/
/*    text-align: center;*/
/*}*/

/*div.sd-description.sd-question__description{*/
/*    color: black;*/
/*    font-weight: 800;*/
/*}*/

/*.info-panel-image {*/
/*    max-height: 400px;*/
/*}*/
